import React from "react"

import Layout from "../components/layout"
import Registry from "../components/registry"
import SEO from "../components/seo"

const RegistryPage = () => (
  <Layout>
    <SEO title="Registry" />
    <Registry />
  </Layout>
)

export default RegistryPage
