import React from "react"
import { Link } from "gatsby"

export default class Registry extends React.Component {
  render() {
    return (
      <div className="main">
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="cover-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <h2>Your presence is the best present</h2>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h4 style={{ margin: "20px 10px" }}>
                    But we've also set up <br />a registry at blueprint.
                  </h4>
                  <Link
                    className="button-link"
                    to="https://www.blueprintregistry.com/registry/RXG-via-TWA"
                    target="_blank"
                  >
                    REGISTRY
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="registry-container full-width iframe">
      //   <iframe className="iframe" scrolling="no" src="https://www.blueprintregistry.com/registry/RXG-via-TWA" title="Registry" width="890px" height="600px"></iframe>
      // </div>
    )
  }
}
